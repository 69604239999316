import { Fragment } from "react";
import withDashboard from "../../HOC/withDashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonBase,
  Button,
  InputAdornment,
  TextField,
  Card,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  getOrderDetail,
  getOrders,
  setSearchOrder,
  setUser,
} from "../../redux/actions/userAction";
import { capitalize, formatNumberWithCommas } from "../../utils";
import "./index.css";
import noTransactionImg from "../../assets/transaction_eth.png";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector(({ User }) => User);
  const {
    filteredOrders,
    paginatedOrders,
    currentOrderPage,
    totalOrderPages,
    orderPerPage,
    orderSearch,
    orderType,
    orderStatus,
  } = User;

  const setTotalPages = (totalpages) => {
    dispatch(
      setUser({
        name: "totalOrderPages",
        value: totalpages,
      })
    );
  };
  const setCurrentPage = (currentPage) => {
    dispatch(
      setUser({
        name: "currentOrderPage",
        value: currentPage,
      })
    );
  };
  const setPaginatedItems = (paginatedItems) => {
    dispatch(
      setUser({
        name: "paginatedOrders",
        value: paginatedItems,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    dispatch(
      setUser({
        name: "orderPerPage",
        value,
      })
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearchOrder(value));
  };

  const onViewOrder = (order) => {
    const orderId = order._id;
    dispatch(
      getOrderDetail({
        data: {
          orderId,
        },
        navigate,
      })
    );
  };

  useEffect(() => {
    dispatch(getOrders());
    // eslint-disable-next-line
  }, []);

  const changeOrderType = (type) => {
    dispatch(
      setUser({
        name: "orderType",
        value: type,
      })
    );
    dispatch(setSearchOrder(orderSearch));
  };

  const changeOrderStatus = (status) => {
    dispatch(
      setUser({
        name: "orderStatus",
        value: status,
      })
    );
    dispatch(setSearchOrder(orderSearch));
  };

  return (
    <Fragment>
      <div className="table-layout-container">
        <div className="order-accordian-row">
          <div className="order-accordian-container">
            <ButtonBase>
              <div
                className={`order-accordian-item left ${
                  orderType === "All" && "selected"
                }`}
                onClick={() => changeOrderType("All")}
              >
                All
              </div>
            </ButtonBase>
            <ButtonBase>
              <div
                className={`order-accordian-item ${
                  orderType === "buy" && "selected"
                }`}
                onClick={() => changeOrderType("buy")}
              >
                Buy
              </div>
            </ButtonBase>
            <ButtonBase>
              <div
                className={`order-accordian-item right ${
                  orderType === "sell" && "selected"
                }`}
                onClick={() => changeOrderType("sell")}
              >
                Sell
              </div>
            </ButtonBase>
          </div>
          <div className="order-status-accordian-container">
            <div className="order-accordian-container">
              <ButtonBase>
                <div
                  className={`order-accordian-item left ${
                    orderStatus === "All" && "selected"
                  }`}
                  onClick={() => changeOrderStatus("All")}
                >
                  All
                </div>
              </ButtonBase>
              <ButtonBase>
                <div
                  className={`order-accordian-item  ${
                    orderStatus === "pending" && "selected"
                  }`}
                  onClick={() => changeOrderStatus("pending")}
                >
                  Pending
                </div>
              </ButtonBase>
              <ButtonBase>
                <div
                  className={`order-accordian-item  ${
                    orderStatus === "completed" && "selected"
                  }`}
                  onClick={() => changeOrderStatus("completed")}
                >
                  Completed
                </div>
              </ButtonBase>
              <ButtonBase>
                <div
                  className={`order-accordian-item right ${
                    orderStatus === "rejected" && "selected"
                  }`}
                  onClick={() => changeOrderStatus("rejected")}
                >
                  Rejected
                </div>
              </ButtonBase>
            </div>
          </div>
        </div>
        <Card className="table-card">
          <div className="table-title">
            <span>List of Orders</span>
            <div>
              <TextField
                fullWidth
                variant="standard"
                className="search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "white" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={onSearch}
                value={orderSearch}
              />
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>Name</th>
                <th>Type</th>
                <th>Amount Sent</th>
                <th>Amount To Recieve</th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                {paginatedOrders.length >= 1 &&
                  paginatedOrders.map((order) => {
                    const _amountSent =
                      order.type === "buy"
                        ? `${order.amountSent} ${order.currency}`
                        : `${order.amountSent} ${order.coin}`;
                    const _amountReceive =
                      order.type === "buy"
                        ? `${order.amountToRecieve} ${order.coin}`
                        : `${order.amountToRecieve} ${order.currency}`;
                    const isBuy = order.type === "buy";
                    const type = capitalize(order.type);
                    const status = capitalize(order.status);
                    return (
                      <Fragment>
                        <tr>
                          <td>{order?.userId?.name}</td>
                          <td className="with-icon">
                            <span
                              className={`td-icon-container ${
                                isBuy ? "receive" : "send"
                              }`}
                            >
                              {isBuy ? (
                                <CallMadeIcon className="table-icon" />
                              ) : (
                                <CallReceivedIcon className="table-icon" />
                              )}
                            </span>
                            <span className="td-icon-text icon-left">
                              {" "}
                              {type}
                            </span>
                          </td>
                          <td>{_amountSent}</td>
                          <td>
                            {formatNumberWithCommas(_amountReceive)}{" "}
                            {order.currency}
                          </td>
                          <td>{status}</td>
                          <td>
                            <div className="action-row">
                              <Button
                                className="action-btn edit"
                                variant="contained"
                                onClick={() => onViewOrder(order)}
                              >
                                {" "}
                                <PreviewIcon />
                                <span> View</span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
            {paginatedOrders.length < 1 && (
              <Fragment>
                <div className="no-transaction-container">
                  <img src={noTransactionImg} className="no-transaction-img" />
                  <div className="no-transaction-text">No Orders Found</div>
                </div>
              </Fragment>
            )}
          </div>
          <Pagination
            currentPage={currentOrderPage}
            setCurrentPage={setCurrentPage}
            items={filteredOrders}
            totalPages={totalOrderPages}
            setTotalPages={setTotalPages}
            itemsPerPage={orderPerPage}
            setPaginatedItems={setPaginatedItems}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Loader />
        </Card>
      </div>
    </Fragment>
  );
};

export default withDashboard(Orders);
