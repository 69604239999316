import countries from "../../constants/countries";
import currencies from "../../constants/currencies";
import languages from "../../constants/languages";
import {
  SET_PROD_SETTINGS,
  SET_PROFILE_FORM,
  SET_SEARCH_ORDER,
  SET_SEARCH_REFERRAL,
  SET_SEARCH_SWAP,
  SET_SEARCH_USER,
  SET_TEST_SETTINGS,
  SET_UPDATE_PASSWORD_FORM,
  SET_UPDATE_PIN,
  SET_USER,
  SET_USER_DETAIL_FORM,
  SET_USER_PROFILE,
  SET_USER_PROFILE_FORM,
  SET_USER_SEARCH_SWAP,
} from "../actions/types";
const initialState = {
  sidebarOpen: true,
  email: "",
  password: "",
  isLoading: false,
  currentUser: null,
  profile: {
    name: "",
    userName: "",
    email: "",
    editEmail: false,
    phoneNumber: "",
    currency: null,
    currencySymbol: "",
    country: null,
    language: null,
    image: "",
  },
  userprofile: {
    name: "",
    userName: "",
    email: "",
    editEmail: false,
    phoneNumber: "",
    currency: null,
    currencySymbol: "",
    country: null,
    language: null,
    image: "",
    referralCode: "",
    referrerId: "",
  },
  selectedCoin: "",
  confirmPinTransaction: ["", "", "", ""],
  confirmPinSwap: ["", "", "", ""],
  swapCoinFromSelect: "",
  swapCoinFrom: "",
  swapCoinToSelect: "",
  swapCoinTo: "",
  range: null,
  swapEstimate: "",
  // Swaps
  swaps: [],
  filteredSwaps: [],
  paginatedSwaps: [],
  currentSwapPage: 1,
  totalSwapPages: 1,
  swapPerPage: 5,
  swapSearch: "",
  selectedSwap: null,
  // User Swaps
  userSwaps: [],
  userFilteredSwaps: [],
  userPaginatedSwaps: [],
  userCurrentSwapPage: 1,
  userTotalSwapPages: 1,
  userSwapPerPage: 5,
  userSwapSearch: "",
  // Users
  users: [],
  filteredUsers: [],
  paginatedUsers: [],
  currentUserPage: 1,
  totalUserPages: 1,
  userPerPage: 5,
  userSearch: "",
  selectedUser: null,
  // Referrals
  referrals: [],
  filteredReferrals: [],
  paginatedReferrals: [],
  currentReferralPage: 1,
  totalReferralPages: 1,
  referralPerPage: 5,
  referralSearch: "",
  selectedReferral: null,
  // Orders
  orders: [],
  filteredOrders: [],
  paginatedOrders: [],
  currentOrderPage: 1,
  totalOrderPages: 1,
  orderPerPage: 5,
  orderSearch: "",
  selectedOrder: {},
  isBuyTransaction: false,
  orderType: "All",
  orderStatus: "All",
  messageText: "",
  shouldAddMessage: false,

  enviroment: null,
  testConfig: null,
  prodConfig: null,
  updatePasswordForm: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
  updatePinForm: {
    currentPin: ["", "", "", ""],
    newPin: ["", "", "", ""],
    confirmPin: ["", "", "", ""],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        profile: {
          ...state.profile,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case SET_USER_DETAIL_FORM: {
      return {
        ...state,
        userprofile: {
          ...state.userprofile,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case SET_UPDATE_PASSWORD_FORM: {
      return {
        ...state,
        updatePasswordForm: {
          ...state.updatePasswordForm,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case SET_UPDATE_PIN: {
      return {
        ...state,
        updatePinForm: {
          ...state.updatePinForm,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case SET_TEST_SETTINGS: {
      return {
        ...state,
        testConfig: {
          ...state.testConfig,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case SET_PROD_SETTINGS: {
      return {
        ...state,
        prodConfig: {
          ...state.prodConfig,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case SET_PROFILE_FORM: {
      let currentUser = action.payload;
      let profile = {};
      const {
        name,
        userName,
        email,
        phoneNumber,
        currency,
        currencySymbol,
        country,
        language,
      } = currentUser;
      profile = {
        name,
        userName,
        email,
        phoneNumber,
        currency,
        currencySymbol,
        country,
        language,
        editEmail: false,
      };
      const selectedCountry = countries.find(
        (countryItem) => countryItem.value === country
      );
      profile.country = selectedCountry;

      const selectedCurrency = currencies.find(
        (currencyItem) => currencyItem.value === currency
      );
      profile.currency = selectedCurrency;

      if (language) {
        const selectedLang = languages.find(
          (langItem) => langItem.value === language
        );
        profile.language = selectedLang;
      } else {
        profile.language = null;
      }

      return {
        ...state,
        profile,
      };
    }
    case SET_USER_PROFILE_FORM: {
      let currentUser = action.payload;
      let profile = {};
      const {
        name,
        userName,
        email,
        phoneNumber,
        currency,
        currencySymbol,
        country,
        language,
        image,
        referralCode,
        referrerId,
      } = currentUser;
      profile = {
        name,
        userName,
        email,
        phoneNumber,
        currency,
        currencySymbol,
        country,
        language,
        image,
      };
      const selectedCountry = countries.find(
        (countryItem) => countryItem.value === country
      );
      profile.country = selectedCountry;

      const selectedCurrency = currencies.find(
        (currencyItem) => currencyItem.value === currency
      );
      profile.currency = selectedCurrency;

      if (language) {
        const selectedLang = languages.find(
          (langItem) => langItem.value === language
        );
        profile.language = selectedLang;
      } else {
        profile.language = null;
      }
      if (image) profile.image = image;
      if (referralCode) profile.referralCode = referralCode;
      if (referrerId) profile.referrerId = referrerId;

      return {
        ...state,
        userprofile: profile,
      };
    }

    case SET_SEARCH_SWAP: {
      const value = action.payload;
      const filteredSwaps = state.swaps.filter((swap) => {
        const regex = new RegExp(value, "i");
        const {
          swapId,
          amountSent,
          coinSent,
          amountToRecieve,
          coinRecieve,
          status,
          date,
        } = swap;

        const transactionDate = new Date(date);
        const day = transactionDate.getDate();
        const month = transactionDate.getMonth() + 1;
        const year = transactionDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        return (
          value === "" ||
          regex.test(swapId) ||
          regex.test(amountSent) ||
          regex.test(coinSent) ||
          regex.test(amountToRecieve) ||
          regex.test(coinRecieve) ||
          regex.test(status) ||
          regex.test(formattedDate)
        );
      });
      return { ...state, filteredSwaps, swapSearch: value };
    }

    case SET_USER_SEARCH_SWAP: {
      const value = action.payload;
      const userFilteredSwaps = state.userSwaps.filter((swap) => {
        const regex = new RegExp(value, "i");
        const {
          swapId,
          amountSent,
          coinSent,
          amountToRecieve,
          coinRecieve,
          status,
          date,
        } = swap;

        const transactionDate = new Date(date);
        const day = transactionDate.getDate();
        const month = transactionDate.getMonth() + 1;
        const year = transactionDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        return (
          value === "" ||
          regex.test(swapId) ||
          regex.test(amountSent) ||
          regex.test(coinSent) ||
          regex.test(amountToRecieve) ||
          regex.test(coinRecieve) ||
          regex.test(status) ||
          regex.test(formattedDate)
        );
      });
      return { ...state, userFilteredSwaps, userSwapSearch: value };
    }

    case SET_SEARCH_REFERRAL: {
      const value = action.payload;
      const filteredReferrals = state.referrals.filter((item) => {
        const regex = new RegExp(value, "i");
        const {
          refferal,
          referre,
          coin,
          amountDeposited,
          amountPaid,
          transactionHx,
          sentTo,
        } = item;

        return (
          value === "" ||
          regex.test(refferal.name) ||
          regex.test(referre.name) ||
          regex.test(coin) ||
          regex.test(amountDeposited) ||
          regex.test(amountPaid) ||
          regex.test(transactionHx) ||
          regex.test(sentTo)
        );
      });
      return { ...state, filteredReferrals, referralSearch: value };
    }

    case SET_SEARCH_USER: {
      const value = action.payload;
      const filteredUsers = state.users.filter((user) => {
        const regex = new RegExp(value, "i");
        const { name, email, phoneNumber, bitcoin, ethereum } = user;
        const { walletAddress } = bitcoin;
        const { address } = ethereum;

        return (
          value === "" ||
          regex.test(name) ||
          regex.test(email) ||
          regex.test(phoneNumber) ||
          regex.test(walletAddress) ||
          regex.test(address)
        );
      });
      return { ...state, filteredUsers, userSearch: value };
    }

    case SET_SEARCH_ORDER: {
      const value = action.payload;
      const filteredOrders = state.orders.filter((order) => {
        const regex = new RegExp(value, "i");
        const { user, type, coin, currency } = order;
        const { name, email, phoneNumber } = user || {};
        const selectedOrderType =
          state.orderType === "All" ? true : order.type === state.orderType;
        const selectedOrderStatus =
          state.orderStatus === "All"
            ? true
            : order.status === state.orderStatus;

        return (
          (value === "" ||
            regex.test(name) ||
            regex.test(email) ||
            regex.test(phoneNumber) ||
            regex.test(type) ||
            regex.test(coin) ||
            regex.test(currency)) &&
          selectedOrderType &&
          selectedOrderStatus
        );
      });
      return { ...state, filteredOrders, orderSearch: value };
    }

    default:
      return state;
  }
};
